import React from 'react';
import Form from '../components/form';

function Contact() {
    return (
        <div className='page-content'>
            <Form/>
        </div>
    )
}

export default Contact;