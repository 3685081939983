import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PersonComponent = () => {
  const { t } = useTranslation('about');
  const images = [
    {
      src: require("../resources/Test_photos/Thibaud.webp"),
      alt: t('lopes'),
      title: t('lopes'),
      description: t('lopes_add'),
      additionalText: t('lopes_more'),
      citationText: t('lopes_cit')
    },
    {
      src: require("../resources/Test_photos/Ygal.webp"),
      alt: t('bounjnah'),
      title: t('bounjnah'),
      description: t('bounjnah_add'),
      additionalText: t('bounjnah_more'),
      citationText: t('bounjnah_cit')
    },
    {
      src: require("../resources/Test_photos/Alexis.webp"),
      alt: t('vaucheret'),
      title: t('vaucheret'),
      description: t('vaucheret_add'),
      additionalText: t('vaucheret_more'),
      citationText: t('vaucheret_cit')
    },
    {
      src: require("../resources/Test_photos/Olivier.webp"),
      alt: t('caetano'),
      title: t('caetano'),
      description: t('caetano_add'),
      additionalText: t('caetano_more'),
      citationText: t('caetano_cit')
    },
    {
      src: require("../resources/Test_photos/Pauline.webp"),
      alt: t('lavefve'),
      title: t('lavefve'),
      description: t('lavefve_add'),
      additionalText: t('lavefve_more'),
      citationText: t('lavefve_cit')
    }
  ];

  const [showTextArray, setShowTextArray] = useState(Array(images.length).fill(false));

  const handleButtonClick = (index) => {
    const newArray = [...showTextArray];
    if (!newArray[index]) {
      newArray.fill(false);
      newArray[index]=true;
    } else {
      newArray[index]=false;
    }
    setShowTextArray(newArray);
  };

  return (
    <div className='container-about'>
      {images.map((image, index) => (<>
        <div key={index} className='container-person'>
          <div className='container-button'>
          <img className={`img-person ${showTextArray[index] ? 'highlighted-image' : ''}`} src={image.src} alt={image.alt} />
          <button className='more-info-button-about' onClick={() => handleButtonClick(index)}>
            {showTextArray[index] ? 'Voir -' : 'Voir +'}
          </button></div>
          <div className='container-text-values'>
            <p className={`value-title ${showTextArray[index] ? 'highlighted-text' : ''}`}>{image.title}</p>
            <p className={`text-xlarge ${showTextArray[index] ? 'highlighted-text' : ''}`}>{image.description}</p>
            
          </div>
           {showTextArray[index] && <div className={`additional-text-about ${showTextArray[index] ? 'fade-in' : ''}`}><p>{image.additionalText}</p><br/><p className='citation-text'>{image.citationText}</p></div>}
        </div>
       
        </>))}
    </div>
  );
};

export default PersonComponent;
