import React from 'react';
import { useTranslation } from 'react-i18next';
const PersonBoardComponent = () => {
  const { t } = useTranslation('about');
  const images = [
    {
      src: require("../resources/Test_photos/Thomas_Balmitgere.webp"),
      alt: t('balmitgere'),
      title: t('balmitgere'),
      description: t('balmitgere_add')
    },
    {
        src: require("../resources/Test_photos/Dr_Martiano.webp"),
        alt: t('martiano'),
        title: t('martiano'),
        description: t('martiano_add')
      },
      {
        src: require("../resources/Test_photos/Faustine.webp"),
        alt: t('perrin'),
        title: t('perrin'),
        description: t('perrin_add')
      },
      {
        src: require("../resources/Test_photos/Thibaud_THAERON.webp"),
        alt: t('thaeron'),
        title: t('thaeron'),
        description: t('thaeron_add')
      }
  ];

  return (
    <div className='container-about'>
      {images.map((image, index) => (
        <div key={index} className='container-person'>
            <img className='img-person' src={image.src} alt={image.alt} />
            <div className='container-text-values'>
                <p className='value-title'>{image.title}</p>
                <p className='text-xlarge wspace'>{image.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PersonBoardComponent;
