import React from "react";
import { useTranslation } from 'react-i18next';

function Modal({ onCloseModal }) {
  const { t } = useTranslation('modal');


  return (
        <div className="modal">
          <div className="overlay"></div>
          <div className="modal-content wspace">
            <p>
              {t('text')}
            </p>
            <button className="close-modal" onClick={onCloseModal}>
              {t('button')}
            </button>
          </div>
        </div>
  );
}

export default Modal;