import React from 'react';
import { useTranslation } from 'react-i18next';
const ValueComponent = () => {
  const { t } = useTranslation('about');
  const images = [
    {
        src: require("../resources/ecoute.webp"),
        alt: t('listening_alt'),
        title: t('listening'),
        text: t('listening_add')
    },
    {
        src: require("../resources/terre.webp"),
        alt: t('accessibility_alt'),
        title: t('accessibility'),
        text: t('accessibility_add')
    },
    {
        src: require("../resources/stars.webp"),
        alt: t('excellence_alt'),
        title: t('excellence'),
        text: t('excellence_add')
    }
  ];

  return (
    <>
      {images.map((image, index) => (
        <div key={index} className={`container-value ${(index%2)===0 ? 'reverse-flow' : ''}`}>
            <img className='container-img' src={image.src} alt={image.alt} />
            <div className='container-text-value'><p className='value-title'>{image.title}</p><p className='text'>{image.text}</p></div>
        </div>
      ))}
    </>
  );
};

export default ValueComponent;
