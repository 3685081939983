import React from 'react';
import { useTranslation } from 'react-i18next';
const BenefitComponent = () => {
  const { t } = useTranslation('product');
  const images = [
    {
        src: require("../resources/test.webp"),
        alt: t('benefits_practi_alt'),
        title: t('benefits_practi'),
        point_1: t('benefits_practi_1'),
        point_2: t('benefits_practi_2'),
        point_3: t('benefits_practi_3'),
        point_4: t('benefits_practi_4'),
        point_5: t('benefits_practi_5'),
        point_6: t('benefits_practi_6')
    },
    {
        src: require("../resources/product-adv.webp"),
        alt: t('benefits_patient_alt'),
        title: t('benefits_patient'),
        point_1: t('benefits_patient_1'),
        point_2: t('benefits_patient_2'),
        point_3: t('benefits_patient_3'),
        point_4: t('benefits_patient_4'),
        point_5: t('benefits_patient_5'),
        point_6: t('benefits_patient_6'),
        point_7: t('benefits_patient_7')
    }
  ];

  return (
    <>
      {images.map((image, index) => (
        <div key={index} className={`container-benefit ${(index%2)===1 ? 'reverse-flow' : ''}`}>
            <img className='container-img-benefit' src={image.src} alt={image.alt} />
            <div className='container-text-benefit'>
                <p className='benefit-title'>{image.title}</p>
                <ul>
                    <li className='text-xlarge'><span>{image.point_1}</span></li>
                    <li className='text-xlarge'><span>{image.point_2}</span></li>
                    <li className='text-xlarge'><span>{image.point_3}</span></li>
                    <li className='text-xlarge'><span>{image.point_4}</span></li>
                    <li className='text-xlarge'><span>{image.point_5}</span></li>
                    <li className='text-xlarge'><span>{image.point_6}</span></li>
                    {image.point_7 && <li className='text-xlarge'><span>{image.point_7}</span></li>}
                </ul>
            </div>
        </div>
      ))}
    </>
  );
};

export default BenefitComponent;
