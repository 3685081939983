import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'fr',
    lng: sessionStorage.getItem('Localelanguage'),
    resources: {
        fr: {
            header: require('./locales/fr/header.json'),
            notice: require('./locales/fr/notice.json'),
            about: require('./locales/fr/about.json'),
            product: require('./locales/fr/product.json'),
            error: require('./locales/fr/error.json'),
            home: require('./locales/fr/home.json'),
            footer: require('./locales/fr/footer.json'),
            clinical: require('./locales/fr/clinical.json'),
            contact: require('./locales/fr/contact.json'),
            product_opt: require('./locales/fr/product_opt.json'),
            modal: require('./locales/fr/modal.json')
        },
        en: {
            header: require('./locales/en/header.json'),
            notice: require('./locales/en/notice.json'),
            about: require('./locales/en/about.json'),
            product: require('./locales/en/product.json'),
            error: require('./locales/en/error.json'),
            home: require('./locales/en/home.json'),
            footer: require('./locales/en/footer.json'),
            clinical: require('./locales/en/clinical.json'),
            contact: require('./locales/en/contact.json'),
            product_opt: require('./locales/en/product_opt.json'),
            modal: require('./locales/en/modal.json')
        }
    },
    ns: ['translations'], // à revoir
    defaultNS: 'translations' // à revoir
});

i18n.languages = ['fr', 'en'];

export default i18n;