import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation('footer');
    return (
        <footer>
            <p>{t('enterprise')}</p>
            <p>{t('copyright')}</p>
            <a href="/legal-notice">{t('legal-notice')}</a>
        </footer>
    )
}

export default Footer;