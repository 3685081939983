import React from 'react';
import { useTranslation } from 'react-i18next';

function NotFound() {
    const { t } = useTranslation('error');
    return (
        <div className='all-content error'>
            <p>{t('title')}</p>
            <p>{t('subtitle')}</p>
            <a className='link' href="..">{t('link')}</a>
        </div>
    )
}

export default NotFound;