import React, { useState } from 'react';

const RectangleComponent = ({ text, additionalText }) => {

  const [expanded, setExpanded] = useState(Array(text.length).fill(false));

  const handleToggleExpand = (index) => {
    const updatedExpanded = [...expanded];
    updatedExpanded[index] = !updatedExpanded[index];
    setExpanded(updatedExpanded);
  };

  return (
    <>
    {text.map((item, index) => (
      <div key={index} className={`rectangle wspace ${expanded[index] ? 'expanded' : ''}`} onClick={() => handleToggleExpand(index)}>
        <div className="content">
          <p style={{fontWeight: `${expanded[index] ? 'bold' : ''}`}}>{item}</p>
          {expanded[index] && <p className="additional-text">{additionalText[index]}</p>}
        </div>
        <button className="expand-button" >
          {expanded[index] ? '-' : '+'}
        </button>
      </div>
    ))}
    </>
  );
};

export default RectangleComponent;