import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExamComponent = () => {
  const { t } = useTranslation('product');
  const images = [
    {
      src: require("../resources/Logos_exams/champ_visuel.png"),
      alt: t('chmp_vsl_alt'),
      title: t('chmp_vsl'),
      description: t('chmp_vsl_desc'),
      additionalText: t('chmp_vsl_add')
    },
    {
      src: require("../resources/Logos_exams/oculomoteurs.png"),
      alt: t('oculo_alt'),
      title: t('oculo'),
      description: t('oculo_desc')
    },
    {
      src: require("../resources/Logos_exams/diametre_pupillaire.png"),
      alt: t('dmtr_ppl_alt'),
      title: t('dmtr_ppl'),
      description: t('dmtr_ppl_desc')
    },
    {
      src: require("../resources/Logos_exams/vision_couleurs.png"),
      alt: t('vsn_clr_alt'),
      title: t('vsn_clr'),
      description: t('vsn_clr_desc')
    },
    {
      src: require("../resources/Logos_exams/vision_relief.png"),
      alt: t('vsn_rlf_alt'),
      title: t('vsn_rlf'),
      description: t('vsn_rlf_desc')
    },
    {
      src: require("../resources/Logos_exams/amplitude_fusion.png"),
      alt: t('amp_fsn_alt'),
      title: t('amp_fsn'),
      description: t('amp_fsn_desc')
    },
    {
      src: require("../resources/Logos_exams/ppc.png"),
      alt: t('ppc_alt'),
      title: t('ppc'),
      description: t('ppc_desc')
    },
    {
      src: require("../resources/Logos_exams/perception_simul.png"),
      alt: t('prcp_smt_alt'),
      title: t('prcp_smt'),
      description: t('prcp_smt_desc')
    }
  ];

  const [showTextArray, setShowTextArray] = useState(Array(images.length).fill(false));

  const handleButtonClick = (index) => {
    const newArray = [...showTextArray];
    newArray[index] = !newArray[index];
    setShowTextArray(newArray);
  };

  return (
    <>
    <p className='text' id='text-exam'>{t('exams')}</p>
    <div className='container-exams'>
        {images.map((image, index) => (
        <div key={index} className='container-exam'>
          <div className='container-button container-button-exam'>
            <div className='container-top-exam'>
                <img className='img-exam' src={image.src} alt={image.alt} />
                <p className='highlighted-text-exam'>{image.title}</p>
            </div>
            <div className='container-bottom-exam'>
                <p>{image.description}</p>
            </div>
            {image.additionalText &&             
              <button className='more-info-button-exam' onClick={() => handleButtonClick(index)}>
                  {showTextArray[index] ? '-' : '+'}
              </button>
            }
          </div>
          <div className='container-text-exam'>
              {showTextArray[index] && image.additionalText && <div className={`additional-text-exam ${showTextArray[index] ? 'fade-in' : ''}`}><p>{image.additionalText}</p></div>}
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default ExamComponent;
