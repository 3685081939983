import React from "react";
import Slider from "react-slick";

function Carousel() {
    var settings = {
        autoplay:true,
        adaptiveHeight:true,   
        infinite: true,
        centerMode: true,
        centerPadding: '0',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    return (
        <Slider {...settings}>
            <a className="slide" href="https://www.chu-brest.fr/fr" target="_blank" rel="noopener noreferrer"><img src={require("../resources/CHU-Brest.webp")} alt="CHU-Brest" /></a>
            <a className="slide" href="https://www.fo-rothschild.fr/" target="_blank" rel="noopener noreferrer"><img src={require("../resources/FOR.webp")} alt="FOR" /></a>
            <a className="slide" href="https://www.meyeclinic.fr/" target="_blank" rel="noopener noreferrer"><img src={require("../resources/M'Eye_clinic.webp")} alt="M'Eye Clinic" /></a>
            <a className="slide" href="https://www.ophtalmo-montluel.fr/" target="_blank" rel="noopener noreferrer"><img src={require("../resources/coc.webp")} alt="Centre Ophtalmologique de la Côtière" /></a>
            <a className="slide" href="https://www.ophtalmo-colline.fr/" target="_blank" rel="noopener noreferrer"><img src={require("../resources/la_colline.webp")} alt="Centre Ophtalmologique de la Colline" /></a>
            <a className="slide" href="https://www.parisandco.paris/expertises-sectorielles2/sante" target="_blank" rel="noopener noreferrer"><img src={require("../resources/techcare.webp")} alt="Tech Care Paris" /></a>
            <a className="slide" href="https://www.wilco-startup.com/" target="_blank" rel="noopener noreferrer"><img src={require("../resources/wilco.webp")} alt="WILCO" /></a>
      </Slider>
    )
}

export default Carousel;