import React from 'react';
import Banner from '../components/banner';
import { useTranslation } from 'react-i18next';

function ProductOPT() {
    const { t } = useTranslation('product_opt');
    return (
        <div className='page-content'>
            <div className='all-content'>
                <Banner text={t('banner')}/>
                <p className='text text-clinical'>{t('textWIP')}</p>
                <br></br>
            </div>
        </div>
    )
}

export default ProductOPT;