import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './banner';
import axios from 'axios';

    function Form() {
        const API_url = process.env.REACT_APP_API_URL;
        const { t } = useTranslation('contact');
        const [showConsent, setShowConsent] = useState(false);
        const [formSubmitted, setFormSubmitted] = useState(false);
    
        const displayConsent = () => {
            console.log("display");
            setShowConsent(!showConsent);
        }

        useEffect(() => {
            const handleResize = () => {
              if (window.innerWidth < "1024") {
                setShowConsent(false);
              } else {
                setShowConsent(true);
              }
            };
        
            window.addEventListener('resize', handleResize);
            handleResize(); 
        
            return () => {
              window.removeEventListener('resize', handleResize);
            };
          }, []);

          const sendEmail = async (e) => {
            e.preventDefault();
        
            try {
              await axios.post(API_url+"/api/contact", {
                firstName: e.target.elements['first-name'].value,
                lastName: e.target.elements['last-name'].value,
                emailAddress: e.target.elements['email-address'].value,
                phoneNumber: e.target.elements['phone-number'].value,
                profession: e.target.elements['profession'].value,
                message: e.target.elements['message-contact'].value,
              });
        
              setFormSubmitted(true);
            } catch (error) {
              console.log(error);
              alert(t('error'));
            }
          };


          return (
            <>
            { !formSubmitted && <>
            <Banner text={t('banner')}/>
            <div className='div-form'>
                <form onSubmit={sendEmail}>
                    <div className='form-element'>
                        <label htmlFor="fname">{t('fname')}</label>
                        <input type="text" id="fname" name="first-name" placeholder={t('fname_placeholder')} required/>
                    </div>
    
                    <div className='form-element'>
                        <label htmlFor="lname">{t('lname')}</label>
                        <input type="text" id="lname" name="last-name" placeholder={t('lname_placeholder')} required/>
                    </div>
    
                    <div className='form-element'>
                        <label htmlFor="email">{t('email')}</label>
                        <input type="email" id="email" name="email-address" placeholder={t('email_placeholder')} required pattern="(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}"/>
                    </div>
    
                    <div className='form-element'>
                        <label htmlFor="phone">{t('phone')}</label>
                        <input type="tel" id="phone" name="phone-number" placeholder={t('phone_placeholder')}  pattern="((\+|00)?[1-9]{2}|0)[1-9]( ?[0-9]){8}"/>
                    </div>
    
                    <div className='form-element'>
                        <label htmlFor="jobs">{t('jobs')}</label>
                        <select id="jobs" name="profession" defaultValue="" required>
                            <option value="" disabled hidden>{t('jobs_placeholder')}</option>
                            <option value={t('oph')}>{t('oph')}</option>
                            <option value={t('ort')}>{t('ort')}</option>
                            <option value={t('opt')}>{t('opt')}</option>
                            <option value={t('other')}>{t('other')}</option>
                        </select>
                    </div>
    
                    <div className='form-element'>
                        <label htmlFor="message">{t('message')}</label>
                        <input type="text" id="message" name="message-contact" placeholder={t('message_placeholder')} required/>
                    </div>
                    <div className='consent-div-text'>
                        <p>{t('indic')}</p>
                        <div className='consent-div-flex'>
                            <p className='subtitle'>{t('consent')}</p>
                            <p className='consentButton' onClick={displayConsent}>
                                {showConsent ? '-' : '+'}
                            </p>
                        </div>
                        {(showConsent || window.innerWidth >= 1024) && 
                            <p className='mobile-check-consent'>{t('consent_text')}</p>
                        }
                    </div>
                    <div className='consent-div'>
                        <input type="checkbox" id="consent" name='box-consent' required/>
                        <label>{t('consent_accept')}</label>
                        <input className='button' type="submit" value={t('button')}/>
                    </div>
    
                </form>
            </div></>}
            { formSubmitted &&
                <div className='wspace all-content'>
                    <Banner text={t('sent_banner')}/>
                    <p className='text-form text'>{t('sent_message')}</p>
                </div>
            }
            </>
        )
    }

export default Form;