import React from 'react';
import RectangleComponent from '../components/legal-notice-rect';
import Banner from '../components/banner';
import { useTranslation } from 'react-i18next';

function LegalNotice() {
    const { t } = useTranslation('notice');
    const texts = [t('editor'), t('terms-use'), t('manager'),t('liability'),t('disputes'),t('access'),t('confidentiality'),t('intellect'),t('terms-service'),t('info'),t('cookies'),t('links'),t('research'),t('usage'),t('responsibility'),t('rgpd')];
    const additionalTexts= [t('editor_add'), t('terms-use_add'), t('manager_add'),t('liability_add'),t('disputes_add'),t('access_add'),t('confidentiality_add'),t('intellect_add'),t('terms-service_add'),t('info_add'),t('cookies_add'),t('links_add'),t('research_add'),t('usage_add'),t('responsibility_add'),t('rgpd_add')];
    return (
        <div className='page-content'>
            <Banner text={t('banner')}/>
            <RectangleComponent text={texts} additionalText={additionalTexts}/>
            <br></br>
        </div>
    )
}

export default LegalNotice;