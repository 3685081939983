import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../components/banner';
import Exams from '../components/exam';
import Benefits from '../components/benefit'


function Product() {
    const { t } = useTranslation('product');
    return (
        <div className='page-content'>

            {/* Partie Examens */}
            <Banner text={t('banner_1')}/>
            <Exams/>

            {/* Partie Avantages */}
            <Banner text={t('banner_2')}/>
            <Benefits/>

            {/* Partie Offre */}
            <Banner text={t('banner_3')}/>
            <div className='container-product'>
                <div className='container-product-img'>
                    <img className='container-img' src={require("../resources/img.webp")} alt="à changer" />
                    <div className='container-offer'>
                        <p className='text offer-title'>{t('offer')}</p>
                        <ul>
                            <li><p className='text'>{t('offer_1')}</p>
                                <ul>
                                    <li><p className='text'>{t('offer_1_1')}</p></li>
                                    <li><p className='text'>{t('offer_1_2')}</p></li>
                                    <li><p className='text'>{t('offer_1_3')}</p></li>
                                </ul>
                            </li>
                            <li><p className='text'>{t('offer_2')}</p>
                                <ul>
                                    <li><p className='text'>{t('offer_2_1')}</p></li>
                                    <li><p className='text'>{t('offer_2_2')}</p></li>
                                </ul>
                            </li>
                            <li><p className='text'>{t('offer_3')}</p></li>
                            <li><p className='text'>{t('offer_4')}</p></li>
                            <li><p className='text'>{t('offer_5')}</p></li>
                        </ul>
                        
                        <p className='text' style={{fontWeight:'bold'}}>{t('available_1')}<span className='highlighted-text'>{t('available_2')}</span> {t('available_3')}<span className='highlighted-text'>{t('available_4')}</span></p>
                    </div>
                </div>
                <a className='button' href="/contact">{t('button')}</a>
            </div>

            {/* Partie Vidéo */}
            <Banner text={t('banner_4')}/>
            <div></div>

        </div>
    )
}

export default Product;