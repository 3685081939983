import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const displayMenu = () => {
        setMenuOpen(!isMenuOpen);
    }
    const { t } = useTranslation('header');
    const { i18n } = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState(sessionStorage.getItem('Localelanguage') === null ? 'fr' : sessionStorage.getItem('Localelanguage'));
    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        sessionStorage.setItem('Localelanguage',newLanguage);
    };

    return (
        <header>
            <div className='top-header'>
                <a href='/'>
                    <img id="img-lhs" src={require("../resources/logo.png")} alt="Logo de l'entreprise"/>
                </a>
                <a href='https://www.linkedin.com/company/look-healsee/?originalSubdomain=fr' target="_blank" rel="noopener noreferrer">
                    <img className='logoLinkedin' src={require("../resources/in.png")} alt="logo linkedin" />
                </a>
                <img id="menuLang" onClick={toggleLanguage} src={currentLanguage === 'en' ? require("../resources/flag_en.png") : require("../resources/flag_fr.png")} alt="icône pour changer de langue" />
                <img id="menuButton" className={`${isMenuOpen ? 'menuButtonOpen' : 'menuButtonClose'}`} onClick={displayMenu} alt="bouton du menu déroulant" />
            </div>

            <div className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                <a className='link' href="/">{t('home')}</a>
                <div><p className='link'>{t('products')}</p>
                    <div className='submenuProduct'>
                        <a className='link' href="/product-oph-ort">{t('productOphOrt')}</a>
                        <a className='link' href="/product-opt">{t('productOpt')}</a>
                    </div>
                </div>
                <a className='link' href="/clinical">{t('clinical')}</a>
                <a className='link' href="/about">{t('about')}</a>
                <a className='link' href="/contact">{t('contact')}</a>
                <p className='link' >{t('login')}</p>
            </div>
        </header>
    )
}

export default Header;