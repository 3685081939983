import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Contact from "./pages/contact";
import About from "./pages/about";
import Clinical from "./pages/clinical";
import Footer from "./components/footer";
import Header from "./components/header";
import NotFound from "./pages/not-found";
import LegalNotice from "./pages/legal-notice";
import Product from "./pages/product";
import ProductOPT from "./pages/product-opt";

function Router() {
    return (
        <>
        <Header/>
        <Routes>
            <Route path="/" element={<Home/>} exact/> 
            <Route path="/about" element={<About/>} />
            <Route path="/clinical" element={<Clinical/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/legal-notice" element={<LegalNotice/>} />
            <Route path="/product-oph-ort" element={<Product/>} />
            <Route path="/product-opt" element={<ProductOPT/>} />
            <Route path='*' element={<NotFound/>}/>
        </Routes>
        <Footer/>
        </>
    )
}

export default Router;