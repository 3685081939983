import React, { useState, useEffect } from 'react';
import Carousel from '../components/carousel';
import Modal from '../components/modal';
import { useTranslation } from 'react-i18next';


function Home() {
    const { t } = useTranslation('home');
    const [isModalOpen, setIsModalOpen] = useState(true);

    if(isModalOpen) {
        document.body.classList.add('active-modal')
      } else {
        document.body.classList.remove('active-modal')
      }

    const closeModal = () => {
        sessionStorage.setItem('seenPopUp',true);
        setIsModalOpen(false);
    };

  useEffect(() => {
    let returningUser = sessionStorage.getItem("seenPopUp");
    setIsModalOpen(!returningUser);
  }, []);

    return (
        <>
            {isModalOpen && <Modal onCloseModal={closeModal} />}
            <div className='top-homepage'>
                <p className='title'>{t('title')}</p>
                <img className='top-homepage' src={require("../resources/bg.webp")} style={{display:"none"}} alt="Fond avec un homme portant un casque de réalité virtuelle"/>
                <p className='subtitle'>{t('subtitle')}</p>
            </div>
            <div className='fade-transition'></div>
            <div className='bottom-homepage'>
                <div className='container-bottom'>
                    <div className='text-xlarge'>
                        <p><b>{t('text1')}</b><br></br>
                        {t('text2')}
                        <br/><br/>
                        <b>{t('text3')}</b><br/>
                        {t('text4')}
                        <br/><br/>
                        {t('text5')}</p>
                    </div>
                    <img id="img-eye" className='container-img' src={require("../resources/oeil.webp")} alt="oeil" loading="lazy"/>
                </div>
                <p className='text-xlarge'>{t('question')}</p>
                <div className='products-display text'>
                    <div className='container-bottom'>
                        <p>{t('oph-ort')}</p>
                        <a className='button' href="/product-oph-ort">{t('product-oph-ort')}</a>
                    </div>
                    <div className='container-bottom'>    
                        <p>{t('opt')}</p>
                        <a className='button' href="/product-opt">{t('product-opt')}</a>
                    </div>
                </div>
            </div>
            <div className='highlight'>
                <p className='title'>{t('partners')}</p>
            </div>
            <div className='carousel'>
                <Carousel/>
            </div>
        </>
    )
}

export default Home;