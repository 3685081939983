import React from 'react';
import Banner from '../components/banner';
import Person from '../components/person';
import PersonBoard from '../components/person-board';
import Value from '../components/value';
import { useTranslation } from 'react-i18next';


function About() {
    const { t } = useTranslation('about');
    return (
        <div className='page-content'>

            {/* Partie Équipe */}
            <Banner text={t('banner_1')}/>
            <Person/>

            {/* Partie Board Médical */}
            <Banner text={t('banner_2')}/>
            <PersonBoard/> {/**ptet balancer t en param*/}

            {/* Partie Valeurs */}
            <Banner text={t('banner_3')}/>
            <Value/>

        </div>
    )
}

export default About;